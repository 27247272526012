.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



body {
  font-family: Titillium Web, sans-serif;
}

h4,
h5 {
  margin: 0;
}

/* global class */
@media (min-width:360px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .main {
    padding: 1.5rem 0 2.5rem;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  min-width: 360px;
}

/* navbar */
.navbar {
  background-color: #333;
  display: block;
}

.navbar .active {
  color: silver;
}

#navbar-sticky {
  position: sticky;
  top: 0;
  z-index: 970;
}

.navbar-content {
  display: flex;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  height: 56px;
  align-items: center;
}

#navbar-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.nav-login {
  display: flex;
  list-style: none;
  margin: 0;
  padding-left: 0;
  margin-left: auto;
}

.navbar-item {
  padding: 0.5rem 0.5rem;
}

.custom-link:hover {
  color: grey;
}

.custom-link {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

#logo {
  font-weight: bold;
}

.navbar ul {
  list-style-type: none;
  display: flex;
  column-gap: 0.25rem;
}


/* mainbody */


table {
  border-collapse: collapse;
  margin: 1rem auto;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

}

thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

td,
th {
  padding: 0.25rem;
}


tbody tr {
  border-bottom: 1px solid #dddddd;
}

tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.card-image-grid {
  display: grid;
  gap: 1rem 0.75rem;
  margin: 1.75rem 0rem;
  /* width: 100%; */
}

@media (min-width: 1160px) {
  #card-image-grid-width {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media (min-width: 960px) and (max-width: 1159.98px) {
  #card-image-grid-width {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media (min-width: 760px) and (max-width: 959.98px) {
  #card-image-grid-width {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 560px) and (max-width: 759.98px) {
  #card-image-grid-width {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 559.98px) {
  #card-image-grid-width {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}


.card-cell {
  width: 100%;
}

.card-cell-image img {
  max-width: 320px;
  aspect-ratio: 320 / 440;
  width: 100%;
  /* border: 1px solid red; */
  border-radius: .5rem;
}

/* pagination */

.pagination ul {
  list-style-type: none;
  display: flex;
  column-gap: 0.25rem;
  height: 2rem;
  padding: 0px;
}

.pagination li {
  border-radius: 0.25rem;
  color: #24292e;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
}

.pagination a {
  display: inline-block;
  padding: .25rem 0.75rem;
}

.pagination .active {
  background-color: #fa7035;
  border: 1px solid #fa7035;
}

.active a {
  color: #fff;
}

.nonactive {
  border: 1px solid #cfcfcf;
}

.nonactive:hover {
  cursor: pointer;
  border: 1px solid #fa7035;

}


#disable {
  pointer-events: none;
  opacity: .25;
}

/* search */
.search-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.search-top {
  display: flex;
  flex-wrap: wrap;
}

.search-top div {
  margin: 0.625rem 0.75rem 0.625rem 0rem;
  align-items: center;
  display: flex;
}

.magnifyglass {
  height: 1rem;
  opacity: 50%;
  left: 1.5rem;
  position: relative;
}

.search-name input {
  border: 1px solid #cfcfcf;
  padding-left: 2em;
  width: 12rem;
  height: 1.5rem;
  font-size: 1rem;
}

.search-set select {
  border: 1px solid #cfcfcf;
  /* padding-right: 0px; */
  height: 1.75rem;
  font-size: 1rem;
  padding-bottom: auto;
  padding-top: auto;
}

.search-set {
  padding-left: 1rem;
}

/* search display option */

.search-display-option {
  align-items: center;
  display: flex;
  margin-left: auto;
}

@media (max-width: 1250px) {
  .search-display-option {
    margin-left: 1rem;
    /* or any other value you want */
  }

}

.search-display-option div {
  align-items: center;
  display: flex;
}

.search-container label {
  margin: 0 0.75em 0 0;
}

.search-display-option select {
  border: 1px solid #cfcfcf;
  padding-right: 0px;
  height: 1.75rem;
  font-size: 1rem;
  margin: 0 0.75em 0 0;
}

select:hover {
  cursor: pointer;

}

.display-page-limit {
  width: 1rem;
}

.logo {
  width: 3rem;
}

/* login */
.header-container h1 {
  color: #24292e;
  display: block;
  font-size: 1.6875rem;
  font-weight: 400;
  line-height: 1.25;
  margin: 0;
  text-transform: none;
  text-align: left;
}

.auth-container {
  max-width: 480px;

}

.form-group {
  margin-top: 1.5rem;
  width: 100%;
  text-align: left;
}

.form-group label {
  color: #24292e;
  display: flex;

  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  margin: 0 0 0.5rem;
  text-align: left;
}

.form-group input {

  height: calc(2.25rem + 2px);
  border: 1px solid #cfcfcf;
  border-radius: 0.25rem;
  color: #24292e;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  text-align: left;
  width: 100%;
}

button {
  border-width: 1px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-width: 100px;
  padding: 0.375rem 0.75rem;
  margin: 1rem 0;
  border-radius: 0.25rem;
  cursor: pointer;
}

.form-group a {
  border-width: 1px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-width: 100px;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}

#signin {
  margin-right: 1rem;
  background-color: #fa7035;
  border-color: #fa7035;
  color: #fff;

}

#register {
  margin-right: auto;
  border-color: #cfcfcf;
  background-color: #fff;
}

/* dashboard */

.dashboard-bar {
  display: grid;
  gap: 0.75rem;
  grid-template-columns: repeat(3, 1fr);
}

.dashboard-card {
  align-items: center;
  border-radius: 0.25rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1.5rem;
}


#card1 {
  background-color: #f1913b;
}

#card2 {
  background-color: #2d9cdb;
}

#card3 {
  background-color: #bb6bd9;
}


.dashboard-card h1 {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.25;
  margin: .25rem 0 0;
}

.dashboard-card h2 {
  font-size: 1rem;
  line-height: 1.5;
  text-transform: none;
  margin: .25rem 0 0;

}

.dashboard-row {
  border-top: 1px solid #e3e3e3;

  grid-template-columns: repeat(2, 1fr);
  /* display: grid; */
  gap: 0 1.75rem;
  margin-top: 1rem;
  width: 100%;
}

.dashboard-row h2 {
  display: flex;
  width: 100%;
  font-size: 1.25rem;
  line-height: 1.3;
  text-align: left;
  padding-right: 0px;
}

.dashboard-row img {
  width: 30px;
}


.dashboard-block {
  max-width: none;
  width: 100%;
  border-collapse: collapse;
}

.dashboard-block a {
  color: #24292e;
  align-items: center;
  display: flex;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.5rem .5rem;
  position: relative;
  text-align: left;
  /* width: 100%; */
  border: 1px solid #dfdfdf;
  margin-bottom: -1px;
  text-decoration: none;
}

.dashboard-block a:hover {
  /* background-color: #e3e3e3; */
  background-color: #e8f0fe;
}


#dashboard-list-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

#dashboard-list-bottom {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.logdata {
  color: #24292e;
  align-items: center;
  display: block;
  font-size: 1rem;
  /* line-height: 1.5; */
  padding: 0.5rem .5rem;
  position: relative;
  text-align: left;
  /* width: 100%; */
  border: 1px solid #dfdfdf;
  margin-bottom: -1px;
  text-decoration: none;
}

.logdata p {
  margin: 0px;
}

/* home */

.home-header {
  position: relative;
  display: grid;
  gap: 0.75rem;
  grid-template-columns: repeat(1, 1fr);
  text-align: left;
  justify-content: center;
  align-items: center;
}


@media (min-width: 960px) {
  .home-block {
    width: 35%;
  }
}

@media (min-width:760px) and (max-width: 959.98px) {
  .home-block {
    width: 50%;
  }
}


@media (min-width:560px) and (max-width: 759.98px) {
  .home-block {
    width: 75%;
  }
} 


/* home text */
.home-block {
  color:white;
  position: absolute;
  margin-left: 10%;
  margin-right: 10%;
  width: 100%;
}

@media (min-width:860px) {
  .home-header img {
    width: 100%;
    /* Limit the width to 100% of the container */
    object-fit: cover;
    object-position: right;
    /* Clip on the right side */
  }
}


@media (min-width:760px)and (max-width: 859.98px) {
  .home-header img {
    height:400px;
    /* Limit the width to 100% of the container */
    object-fit: cover;
    object-position: right;
    /* Clip on the right side */
  }
}

@media (min-width:560px) and (max-width: 759.98px) {
  .home-header img {
    width: 100%;
    
    height:400px;
    /* Limit the width to 100% of the container */
    object-fit: cover;
    object-position: right;
    /* Clip on the right side */
  }
}

@media (max-width: 759.98px) {
  .home-header img {
    
    height:400px;
    width: 100%;
    /* Limit the width to 100% of the container */
    object-fit: cover;
    object-position: left;
    /* Clip on the right side */
    min-width: 360px;
  }
}


/* CARDS */

.card-page-inner-container {
  display: flex;
}

.card-cell-control p {
  background-color: #e8f0fe;
  padding: .15rem .25rem;
  margin: .1rem;
  border-radius: .25rem;
  font-size: 20px;
}

.card-cell-control {}

.card-image-container {
  border-radius: 0.375rem;
  display: block;
  height: auto;
  width: 100%;
  vertical-align: middle;
  margin-right: 2rem;
}

@media (min-width: 768px) {
  .card-info {
    margin: 0 auto;
    max-width: 720px;
    min-width: 440px;
    width: 100%;
    text-align: left;
  }

}

.card-block {
  padding-bottom: .25rem;
  display: inline-block;
  border-bottom: 1px solid #e3e3e3;
  width: 100%;
}


.card-info h1 {
  color: #106bc5;
  font-size: 1.6875rem;
  font-weight: 400;
  line-height: 1.25;
  display: block;
}

.modal-body h1 {
  /* color: #106bc5; */
  /* font-size: 1.6875rem; */
  font-weight: 400;
  line-height: 1.25;
  display: block;
}

.modal-body label {
  /* color: #106bc5; */
  /* font-size: 1.6875rem; */
  font-weight: 400;
  line-height: 1.25;
  display: block;
}


.card-info h2 {

  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 0.75rem;
  /* display: block; */

}

.card-info p {
  color: #24292e;
  font-family: Titillium Web, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  /* display: block; */
}

.cc-update-container h2 {
  /* font-size: 1.375rem; */
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

#flex {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

#flex h2 {
  font-weight: 500;
  line-height: 1.25;
  display: block;
}

#flex div:hover {
  background-color: #e8f0fe;
}


/* ##################### POP UP MENU STYLE ############################ */
.dropDownMenu {
  position: absolute;
  top: 4.5rem;
  right: 1.5rem;
  width: 200px;
  border-radius: 8px;
  background-color:
    white;
  border: 1px solid gray;
  padding: 0px;
}

.dropDownMenu li {
  padding: .3rem 1rem;
  list-style: none;
  text-align: left;
}


.dropDownMenu li:hover {
  cursor: pointer;
  background-color: #cfcfcf;
  background-color: #e8f0fe;
}

.dropDownMenu ul {
  list-style: none;
  padding: 10px;
  margin: 0px;
}

.dropDownMenu::before {
  content: '';
  position: absolute;
  top: -0.7rem;
  left: 1.1rem;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  background-color:
    white;
  border-left: 1px solid gray;
  border-top: 1px solid gray;

}

/* ##################### MODAL MENU STYLE ############################ */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000000e1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}

.modal {
  width: clamp(50%, 700px, 90%);
  height: min(50%, auto);
  margin: auto;
  padding: 0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.modal h1 {
  font-size: 1.25rem;

}

.modal-header {
  background-color: #fff;
  border-bottom: 1px solid #e3e3e3;
  padding: 1.125rem 1.25rem;
  width: 100%;
}

.modal-body {
  background-color: #fff;
  overflow-y: auto;
  padding: 1.25rem;
  width: 100%;
}

.modal-body label {
  color: #24292e;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  margin: 0.5rem 0.5rem 0.5rem 1rem;
  /* text-align: left; */
}

.modal-body input {
  border: 1px solid #cfcfcf;
  padding-right: 0px;
  padding-left: 1rem;
  padding-bottom: auto;
  padding-top: auto;
  width: 12rem;
  height: 1.5rem;
  font-size: 1rem;
  margin: 0.5rem;
  
  width: auto;
}

.modal-body select {
  border: 1px solid #cfcfcf;
  /* padding-right: 0px; */
  height: 1.75rem;
  font-size: 1rem;
  padding-bottom: auto;
  padding-top: auto;
  margin: 0.5rem;

}

.field {
  display: flex;
}



#select-grade {
  display: block;
  padding: 1rem;
}
#price{
  display: block;
  text-align: center;
}

.modal-body button{
  margin-top: 1rem;
  background-color: #fa7035;
  border-color: #fa7035;
  color: #fff;
  border-width: 1px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-width: 100px;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.modal-body button:hover{
background-color: #c94a14;
}

#center{
  align-items: center;
  justify-content: center;
}
#center button{
  margin: auto 0;
}

#disabledUpdateButton{
  background-color: #cfcfcf;
  border-color: #cfcfcf;
}